import React, { useCallback, useState, useRef } from 'react';

import { Menu, MenuItem, Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { ArrowIcon } from '../../../assets/icons/ArrowIcon';
import { useStyles } from '../newDropdownMenu/NewDropdownMenu.styles';

import { useStyles as useStylesNested } from './NestedMenuItem.styles';
import { NestedMenuItemProps } from './NestedMenuItem.types';

export const NestedMenuItem = ({ menuItem, handleNestedMenuItemClick, setParentMenuShow }: NestedMenuItemProps) => {
  const classes = useStyles();
  const classesNested = useStylesNested();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isNestedMenuOpen, setNestedMenuOpen] = useState<HTMLElement | null>(null);
  const alreadyClicked = useRef(false);

  const handleNestedMenuOpen = useCallback(
    (event: { currentTarget: HTMLElement }) => {
      if (isNestedMenuOpen !== event.currentTarget) {
        setNestedMenuOpen(event.currentTarget);
        alreadyClicked.current = true;

        if (isMobile) {
          setParentMenuShow(false);
        }
      }
    },
    [isNestedMenuOpen, setNestedMenuOpen, setParentMenuShow, isMobile],
  );

  const handleNestedMenuClose = useCallback(() => {
    setNestedMenuOpen(null);

    if (isMobile) {
      setParentMenuShow(true);
    }
  }, [setNestedMenuOpen, setParentMenuShow, isMobile]);

  const handleClick = useCallback(
    (event: { currentTarget: HTMLElement }) => {
      if (isNestedMenuOpen === event.currentTarget && !alreadyClicked.current) {
        setNestedMenuOpen(null);
      } else {
        alreadyClicked.current = false;
      }

      if (isNestedMenuOpen !== event.currentTarget && !alreadyClicked.current) {
        setNestedMenuOpen(event.currentTarget);
      }
    },
    [isNestedMenuOpen, setNestedMenuOpen],
  );

  const onClickNestedMenuItem = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      if (handleNestedMenuItemClick) {
        handleNestedMenuItemClick(event);
      }
    },
    [handleNestedMenuItemClick],
  );

  return (
    <MenuItem
      id={menuItem.htmlId}
      className={classes.menuItem}
      onClick={isMobile ? handleNestedMenuOpen : handleClick}
      onMouseEnter={isMobile ? undefined : handleNestedMenuOpen}
      onMouseLeave={isMobile ? undefined : handleNestedMenuClose}
    >
      {isMobile ? (
        <>
          {menuItem.name}
          <ArrowIcon className={clsx(classes.icon, classesNested.arrowIcon)} />
        </>
      ) : (
        <>
          <ArrowIcon className={clsx(classes.icon, classesNested.arrowIcon)} />
          {menuItem.name}
        </>
      )}

      <Menu
        className={classesNested.menu}
        classes={{
          paper: classes.menuWrapper,
          list: clsx(classes.list, classesNested.list),
        }}
        open={Boolean(isNestedMenuOpen)}
        anchorEl={isNestedMenuOpen}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ onMouseLeave: handleNestedMenuClose }}
        onClose={handleNestedMenuClose}
      >
        <div onMouseLeave={handleNestedMenuClose}>
          {isMobile && (
            <Grid container className={classesNested.header} alignItems="center" onClick={handleNestedMenuClose}>
              <ArrowIcon className={clsx(classes.icon, classesNested.arrowIcon)} />
              <Typography variant="overline">{menuItem.name}</Typography>
            </Grid>
          )}

          {menuItem.items.map((subMenuItem, i) => (
            <div key={i} className="menuItem">
              <MenuItem
                id={subMenuItem.htmlId ?? `menu-nested-option-${i + 1}`}
                className={classes.menuItem}
                data-name={`${menuItem.name}/${subMenuItem.name}`}
                onClick={onClickNestedMenuItem}
              >
                {subMenuItem.name}
              </MenuItem>
            </div>
          ))}
        </div>
      </Menu>
    </MenuItem>
  );
};
