import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { colors } from 'assets/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    animateOpenPopover: {
      animation: `$animateOpenPopover 289ms ${theme.transitions.easing.easeIn}`,
      '@global': {
        '@keyframes animateOpenPopover': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
    animateClosePopover: {
      animation: `$animateClosePopover 289ms ${theme.transitions.easing.easeOut}`,

      '@global': {
        '@keyframes animateClosePopover': {
          '0%': {
            opacity: 1,
          },

          '100%': {
            opacity: 0,
          },
        },
      },
    },
    popoverPaper: {
      minWidth: 0,
      boxShadow: 'none',
    },
    menuWrapper: {
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
      minWidth: 210,

      [theme.breakpoints.down('sm')]: {
        top: 'auto!important',
        left: '0!important',
        bottom: '0!important',
        width: '100%!important',
        maxWidth: '100%!important',
        borderRadius: '10px 10px 0 0',
        maxHeight: '69%',
      },
    },
    list: {
      padding: 0,

      '& .listItem:nth-child(even)': {
        backgroundColor: colors.grey10,
      },
    },
    menuItem: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      padding: '8px 20px 8px 16px',
      fontWeight: 600,
      color: colors.basic.black,
      fontSize: 16,
      lineHeight: '24px',

      [theme.breakpoints.up('md')]: {
        '&:hover': {
          background: colors.secondary.main,
          color: colors.basic.white,

          '& $icon': {
            '& path': {
              fill: colors.basic.white,
            },
          },
        },
      },

      [theme.breakpoints.down('sm')]: {
        padding: '16px',

        '& $icon': {
          transform: 'rotate(0deg)',
        },
      },
    },
    menuItemIfThereNested: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 35,
      },
    },
    icon: {
      width: '8px',
      height: '13px',
      justifySelf: 'flex-end',
      alignItems: 'flex-end',
    },
    divider: {
      borderBottom: `1px dashed ${colors.grey40}`,
    },
  }),
);
