import { Fragment, memo, useMemo, useState } from 'react';

import { Collapse, Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ArrowRight, colors } from 'assets';

import {
  ListStyled,
  ListItemStyled,
  ListItemTextStyled,
  NestedListItemStyled,
  NestedListItemTextStyled,
  useStyles,
} from './SidenavMenuItem.styles';
import { SidenavMenuItemProps } from './SidenavMenuItem.types';

export const SidenavMenuItem = memo(({ menuItem, isSelected, isExpanded, classes, onClick }: SidenavMenuItemProps) => {
  const styles = useStyles();

  const [selectedIndexInner, setSelectedIndexInner] = useState<number | undefined>();

  const selectedIndexOutside = useMemo(
    () => menuItem.nestedItems?.findIndex(nestedItem => nestedItem.isActive),
    [menuItem.nestedItems],
  );

  const selectedIndex = useMemo(
    () => ((selectedIndexOutside ?? -1) >= 0 ? selectedIndexOutside : selectedIndexInner),
    [selectedIndexOutside, selectedIndexInner],
  );

  const badgeElement = useMemo(() => {
    if (!menuItem.badge || menuItem.badge.visible === false) {
      return null;
    }

    const contentTypeIsString = typeof menuItem.badge.content === 'string';

    return (
      <div className={clsx(styles.badge, menuItem.badge.type)}>
        {contentTypeIsString ? (
          <Typography variant="h5" color="inherit" className={styles.badgeTitle}>
            {menuItem.badge.content}
          </Typography>
        ) : (
          menuItem.badge.content
        )}
      </div>
    );
  }, [menuItem.badge, styles]);

  return (
    <div>
      <ListItemStyled button selected={isSelected} className={classes?.listItem} onClick={() => onClick()}>
        <div className={styles.listItemContent}>
          <ListItemTextStyled
            primary={menuItem.element || menuItem.title}
            classes={{ primary: classes?.listItemText }}
          />

          {!!badgeElement && badgeElement}
        </div>

        {menuItem.nestedItems && (
          <ArrowRight
            className={clsx(styles.arrowIcon, isExpanded && styles.arrowIconDown)}
            fontSize={12}
            color={colors.basic.black}
          />
        )}
      </ListItemStyled>

      {menuItem.nestedItems && (
        <Collapse
          in={isExpanded}
          addEndListener={() => {
            if (!isSelected) {
              setSelectedIndexInner(undefined);
            }
          }}
          timeout="auto"
          unmountOnExit={true}
        >
          <ListStyled disablePadding className={clsx(styles.nestedList, classes?.nestedList)}>
            {menuItem.nestedItems?.map((nestedItem, index) => (
              <Fragment key={index}>
                <NestedListItemStyled
                  button
                  selected={index === selectedIndex && nestedItem.isActive !== false}
                  className={classes?.nestedListItem}
                  onClick={() => {
                    onClick(index);
                    setSelectedIndexInner(index);
                  }}
                >
                  <div className={styles.listItemContent}>
                    <NestedListItemTextStyled
                      primary={nestedItem.title}
                      classes={{ primary: classes?.nestedListItemText }}
                    />

                    {!!badgeElement && badgeElement}
                  </div>
                </NestedListItemStyled>

                {nestedItem.separator && <Divider />}
              </Fragment>
            ))}
          </ListStyled>
        </Collapse>
      )}
    </div>
  );
});
