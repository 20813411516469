import { useEffect, useState, useCallback, memo, Fragment, useMemo } from 'react';

import { Divider } from '@material-ui/core';
import clsx from 'clsx';

import { ListStyled, useStyles } from './SidenavMenu.styles';
import { SidenavMenuProps } from './SidenavMenu.types';
import { SidenavMenuItem } from './SidenavMenuItem/SidenavMenuItem';

export const SidenavMenu = memo(({ menuItems, classes }: SidenavMenuProps) => {
  const styles = useStyles();

  const [selectedIndexInner, setSelectedIndexInner] = useState<number | undefined>();
  const [expanded, setExpanded] = useState<number | undefined>();

  const selectedIndexOutside = useMemo(
    () =>
      menuItems.findIndex(
        menuItem => menuItem.isActive || menuItem.nestedItems?.find(nestedItem => nestedItem.isActive),
      ),
    [menuItems],
  );

  const selectedIndex = useMemo(
    () => (selectedIndexOutside >= 0 ? selectedIndexOutside : selectedIndexInner),
    [selectedIndexOutside, selectedIndexInner],
  );

  const handleItemClick = useCallback(
    (index: number, nestedIndex?: number) => {
      const clickedItem = menuItems[index];

      const isHasNestedItems = !!clickedItem.nestedItems;
      const isClickedNestedItem = nestedIndex !== undefined;

      if (!isHasNestedItems) {
        if (clickedItem.isActive === undefined) {
          setSelectedIndexInner(index);
        }
      } else {
        if (!isClickedNestedItem) {
          setExpanded(prev => (prev === index ? undefined : index));
        } else {
          const clickedNestedItem = clickedItem.nestedItems?.[nestedIndex];

          if (clickedNestedItem?.isActive === undefined) {
            setSelectedIndexInner(index);
          }

          setExpanded(index);

          if (clickedNestedItem?.onClick) {
            clickedNestedItem.onClick();
          }
        }
      }

      if (clickedItem.onClick && (!isHasNestedItems || !isClickedNestedItem)) {
        clickedItem.onClick();
      }
    },
    [menuItems],
  );

  useEffect(() => {
    if (selectedIndex === undefined) {
      setExpanded(undefined);

      return;
    }

    const selectedItemNestedItems = menuItems[selectedIndex]?.nestedItems;

    setExpanded(!!selectedItemNestedItems ? selectedIndex : undefined);
  }, [selectedIndex, menuItems]);

  return (
    <ListStyled className={clsx(styles.parentList, classes?.list)}>
      {menuItems.map((menuItem, index) => (
        <Fragment key={index}>
          <SidenavMenuItem
            menuItem={menuItem}
            isSelected={index === selectedIndex && menuItem.isActive !== false}
            isExpanded={index === expanded}
            classes={classes}
            onClick={nestedIndex => handleItemClick(index, nestedIndex)}
          />

          {menuItem.separator && <Divider />}
        </Fragment>
      ))}
    </ListStyled>
  );
});
