import { FC, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Popper } from '@material-ui/core';
import clsx from 'clsx';
import { image, link } from 'suneditor/src/plugins';
import SunEditor from 'suneditor-react';

import 'suneditor/dist/css/suneditor.min.css';

import { colors } from 'assets';
import { Paper } from 'components/paper/Paper';

import { editorDefaultOptions } from './editorDefaultOptions';
import { addImagePlugin } from './imagePlugin/ImagePlugin';
import { ImagePluginInput } from './imagePlugin/ImagePluginInput';
import { useStyles } from './TextEditor.styles';
import { TextEditorProps, User } from './TextEditor.types';
import { UsersList } from './usersList/UsersList';

export const TextEditor: FC<TextEditorProps> = forwardRef(
  ({
    containerClassName,
    disabled,
    error,
    onChange: onChangeType,
    onInputType,
    mentionProps,
    options,
    value = '',
    height,
    imageSupport,
    plugins,
    getInstance,
    toggleCodeView,
    onImageUploadBefore,
    ...props
  }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState<User[]>(
      mentionProps?.users?.sort((a, b) => a.fullName.localeCompare(b.fullName)) ?? [],
    );
    const [isMentionSelected, setIsMentionSelected] = useState(false);

    const styles = useStyles({
      disableActions: showDropdown || isMentionSelected,
      disabled: !!disabled,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const suneditor = useRef<any | null>();
    const isPremention = useRef(false);
    const showDropDownRef = useRef(false);
    const preMentionSelection = useRef<HTMLElement | null>(null);
    const lastSelection = useRef<null | HTMLElement>(null);
    const mentionedIds = useRef<number[]>(mentionProps?.mentionesId ?? []);

    const removeAssignee = useCallback(
      (content: string) => {
        if (mentionProps && mentionedIds.current.length) {
          const deletedMentions: number[] = [];
          mentionedIds.current.forEach(mentionId => {
            if (!content.includes(`id="mention-${mentionId}"`)) {
              deletedMentions.push(mentionId);
              mentionProps.remove(mentionId);
            }
          });

          mentionedIds.current = mentionedIds.current.filter(item => !deletedMentions.includes(item));
        }
      },
      [mentionProps],
    );

    const onChange = useCallback(
      (content: string) => {
        if (onChangeType) {
          removeAssignee(content);

          onChangeType(content.replace(/\u200B/g, ''));
        }
      },
      [removeAssignee, onChangeType],
    );

    const onInput = useCallback(
      e => {
        if (onInputType) {
          const content: string = e.target?.innerHTML;

          if (content) {
            removeAssignee(content);

            onInputType(content.replace(/\u200B/g, ''));
          }
        }
      },
      [removeAssignee, onInputType],
    );

    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        const selection = suneditor.current.core.getSelection();

        if (e.key === '@' && mentionProps) {
          isPremention.current = true;
          const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
          newNode.ariaHidden = 'true';
          newNode.className = clsx(styles.preMention, 'pre-mention');
          newNode.innerHTML = '&#8203;';

          const preMentionedNode = suneditor.current.core.insertNode(newNode);
          preMentionSelection.current = preMentionedNode;

          setShowDropdown(true);
          showDropDownRef.current = true;
        }

        if (showDropDownRef.current && e.code === 'Space') {
          setShowDropdown(false);
          showDropDownRef.current = false;
        }

        if (selection.focusNode?.parentNode?.className.split(' ').includes('pre-mention') && e.code === 'ArrowUp') {
          if (preMentionSelection.current) {
            suneditor.current.util.removeItem(preMentionSelection.current);
            const selectionNode = suneditor.current.core.getSelectionNode();

            suneditor.current.core.setRange(selectionNode, selectionNode.length, selectionNode, selectionNode.length);

            const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
            newNode.innerHTML = preMentionSelection.current.innerText;
            const insertedNode = suneditor.current.core.insertNode(newNode);

            suneditor.current.core.setRange(insertedNode, insertedNode.length, insertedNode, insertedNode.length);

            preMentionSelection.current = null;
            isPremention.current = false;
            setShowDropdown(false);
          }
        }

        if (
          selection?.focusNode?.parentNode &&
          selection.focusNode?.parentNode.className.split(' ').includes('mention')
        ) {
          suneditor.current.util.removeItem(selection.focusNode.parentNode.parentNode.parentNode.parentNode);
          suneditor.current.core.nodeChange(null, null, ['SPAN'], false);

          setIsMentionSelected(false);
        }
      },
      [mentionProps],
    );

    const onKeyUp = useCallback(
      (e: KeyboardEvent) => {
        const selection = suneditor.current.core.getSelection();

        if (
          selection?.focusNode?.parentNode &&
          selection.focusNode?.parentNode?.className.split(' ').includes('pre-mention')
        ) {
          const text = (selection.focusNode.data as string).trim().replace('@', '').toLowerCase();

          setFilteredUsers(
            (mentionProps?.users ?? [])
              .filter(item => item.fullName.toLowerCase().includes(text))
              .sort((a, b) => a.fullName.localeCompare(b.fullName)),
          );
        }

        if (
          (selection?.focusNode?.parentNode &&
            !selection.focusNode?.parentNode.className.split(' ').includes('pre-mention') &&
            isPremention.current) ||
          (isPremention.current && e.code === 'Space')
        ) {
          if (preMentionSelection.current) {
            suneditor.current.util.removeItem(preMentionSelection.current);
            const selectionNode = suneditor.current.core.getSelectionNode();

            if (e.code !== 'ArrowLeft' && e.code !== 'Backspace') {
              suneditor.current.core.setRange(
                selectionNode,
                selectionNode.length - 1,
                selectionNode,
                selectionNode.length - 1,
              );
            }

            const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
            newNode.innerHTML = preMentionSelection.current.innerText;

            const insertedNode = suneditor.current.core.insertNode(newNode);

            if (e.code === 'ArrowLeft') {
              suneditor.current.core.setRange(insertedNode, 0, insertedNode, 0);
            }

            preMentionSelection.current = null;
            isPremention.current = false;
            setShowDropdown(false);
          }
        }

        suneditor.current.onInput(
          new InputEvent('input', {
            bubbles: true,
            data: Array.from(document.getElementsByClassName('se-wrapper-wysiwyg'))[0].innerHTML,
          }),
        );
      },
      [mentionProps?.users],
    );

    const handleUserSelect = (user: User) => {
      if (!suneditor.current) {
        return;
      }

      const selectionNode = suneditor.current.core.getSelectionNode();
      suneditor.current.util.removeItem(selectionNode);
      suneditor.current.core.nodeChange(null, null, ['SPAN'], false);

      suneditor.current.core.nodeChange(null, null, null, false);

      const mentionContentWrap: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mentionContentWrap.contentEditable = 'false';
      mentionContentWrap.className = 'mentionContentWrap';

      const zeroWidthSpaceContainer: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      zeroWidthSpaceContainer.ariaHidden = 'true';
      zeroWidthSpaceContainer.contentEditable = 'false';
      zeroWidthSpaceContainer.className = 'zeroWidthSpaceContainer';

      const inlineNodeViewAddZeroWidthSpace: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      inlineNodeViewAddZeroWidthSpace.contentEditable = 'false';
      inlineNodeViewAddZeroWidthSpace.className = 'inlineNodeViewAddZeroWidthSpace';

      zeroWidthSpaceContainer.appendChild(inlineNodeViewAddZeroWidthSpace);
      zeroWidthSpaceContainer.appendChild(document.createRange().createContextualFragment('&#8203;'));

      const mentionWrap: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mentionWrap.ariaHidden = 'true';

      const clearSpan: HTMLElement = suneditor.current.core.util.createElement('SPAN');

      const mention: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mention.id = `mention-${user.id}`;
      mention.style.color = colors.grey160;
      mention.style.fontWeight = 'bold';
      mention.style.backgroundColor = colors.grey10;
      mention.style.padding = '0px 8px';
      mention.style.borderRadius = '60px';
      mention.className = 'mention';
      mention.innerHTML = `${user.fullName}`;

      clearSpan.appendChild(mention);
      mentionWrap.appendChild(clearSpan);

      const inlineNodeViewAddZeroWidthSpace2: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      inlineNodeViewAddZeroWidthSpace2.contentEditable = 'false';
      inlineNodeViewAddZeroWidthSpace2.className = 'inlineNodeViewAddZeroWidthSpace';

      inlineNodeViewAddZeroWidthSpace2.appendChild(document.createRange().createContextualFragment('&#8203;'));

      const zeroWidthSpaceContainer2: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      zeroWidthSpaceContainer2.ariaHidden = 'true';
      zeroWidthSpaceContainer2.contentEditable = 'false';
      zeroWidthSpaceContainer2.className = 'zeroWidthSpaceContainer';
      zeroWidthSpaceContainer2.appendChild(inlineNodeViewAddZeroWidthSpace2);

      mentionContentWrap.appendChild(zeroWidthSpaceContainer);
      mentionContentWrap.appendChild(mentionWrap);
      mentionContentWrap.appendChild(zeroWidthSpaceContainer2);

      const selectionNode2 = suneditor.current.core.getSelectionNode();
      const selection = suneditor.current.core.getSelection();

      if (selection.focusOffset === selectionNode2.length) {
        suneditor.current.core.setRange(
          selectionNode2,
          selectionNode2.length - 1,
          selectionNode2,
          selectionNode2.length - 1,
        );
      }

      suneditor.current.core.insertNode(mentionContentWrap);

      const text = suneditor.current.core.util.createTextNode(' ');
      suneditor.current.core.insertNode(text);

      const selectionNodeAfterInsert = suneditor.current.core.getSelectionNode();
      suneditor.current.core.setRange(selectionNodeAfterInsert, 1, selectionNodeAfterInsert, 1);

      if (mentionProps) {
        mentionProps.add(user.id);
        mentionedIds.current = [...mentionedIds.current, user.id];
      }

      suneditor.current.onInput(
        new InputEvent('input', {
          bubbles: true,
          data: Array.from(document.getElementsByClassName('se-wrapper-wysiwyg'))[0].innerHTML,
        }),
      );

      showDropDownRef.current = false;
      isPremention.current = false;
      setShowDropdown(false);
      setFilteredUsers(mentionProps?.users?.sort((a, b) => a.fullName.localeCompare(b.fullName)) ?? []);
    };

    const onClick = useCallback(() => {
      const selection = suneditor.current.core.getSelection();
      const selectionNode = suneditor.current.core.getSelectionNode();
      const { focusOffset } = selection;

      if (
        selection?.focusNode?.parentNode &&
        selection.focusNode?.parentNode.className.split(' ').includes('mention')
      ) {
        selection.focusNode.parentNode.style.backgroundColor = colors.functionals.infoLight;
        setIsMentionSelected(true);

        if (lastSelection.current && lastSelection.current !== selection.focusNode?.parentNode) {
          lastSelection.current.style.backgroundColor = colors.grey10;
          setIsMentionSelected(false);
        }
        lastSelection.current = selection.focusNode?.parentNode;
      } else if (lastSelection.current) {
        lastSelection.current.style.backgroundColor = colors.grey10;
        lastSelection.current = null;
        setIsMentionSelected(false);
      }

      if (
        selection?.focusNode?.parentNode &&
        !selection.focusNode?.parentNode.className.split(' ').includes('pre-mention') &&
        isPremention.current
      ) {
        if (preMentionSelection.current) {
          const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
          newNode.innerHTML = preMentionSelection.current.innerText;
          suneditor.current.core.insertNode(newNode, preMentionSelection.current);

          suneditor.current.util.removeItem(preMentionSelection.current);

          suneditor.current.core.setRange(selectionNode, focusOffset, selectionNode, focusOffset);

          preMentionSelection.current = null;
          isPremention.current = false;
          setShowDropdown(false);
        }
      }
    }, []);

    const imgInputRef = useRef<HTMLInputElement>(null);
    const [inputShown, setInputShown] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        if (imgInputRef.current) {
          setInputShown(true);
          clearInterval(interval);
        }
      }, 10);
    }, []);

    const imgInputOnChange = useCallback((files: FileList) => {
      if (suneditor.current && files.length) {
        suneditor.current.insertImage(files);
      }
    }, []);

    const combinedPlugins = useMemo(() => {
      const combined = plugins || [link];

      if (imageSupport) {
        combined.push(addImagePlugin(imgInputRef), image);
      }

      return combined;
    }, [imageSupport, plugins]);

    return (
      <div style={{ position: 'relative' }}>
        <ImagePluginInput ref={imgInputRef} handleChange={imgInputOnChange} />

        <div className={clsx(styles.editorWrapper, containerClassName, error && styles.error)} {...props}>
          {inputShown && (
            <SunEditor
              defaultValue={value}
              setContents={value}
              setOptions={{
                ...editorDefaultOptions({
                  plugins: combinedPlugins,
                }),
                imageResizing: !!imageSupport,
                imageHeightShow: !!imageSupport,
                imageFileInput: !!imageSupport,
                imageUrlInput: !!imageSupport,
                ...options,
              }}
              onChange={onChange}
              onInput={onInput}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onClick={onClick}
              disable={disabled}
              height={height}
              getSunEditorInstance={sunEditor => {
                suneditor.current = sunEditor;

                if (getInstance) {
                  getInstance(sunEditor);
                }
              }}
              onImageUploadBefore={onImageUploadBefore}
              toggleCodeView={toggleCodeView}
            />
          )}
        </div>

        <Popper
          anchorEl={preMentionSelection.current}
          open={showDropdown && !!filteredUsers.length}
          placement="bottom-start"
          style={{ zIndex: 1301 }}
        >
          <Paper elevation={16}>
            <Box border={`0.5px solid ${colors.grey60}`} borderRadius={4} overflow="hidden">
              <UsersList users={filteredUsers} onUserSelect={handleUserSelect} />
            </Box>
          </Paper>
        </Popper>
      </div>
    );
  },
);
