import { FC, memo, useMemo } from 'react';

import { ISplitFactoryChildProps, SplitFactoryProvider } from '@splitsoftware/splitio-react';

import { Loader } from 'components';

export const SplitIoProvider: FC<{ sdkKey: string; customerKey: string; attributes?: SplitIO.Attributes }> = memo(
  ({ sdkKey, customerKey, attributes, children }) => {
    const config: SplitIO.IBrowserSettings = useMemo(
      () => ({
        core: {
          authorizationKey: sdkKey,
          key: customerKey,
        },
      }),
      [],
    );

    return (
      <SplitFactoryProvider config={config} updateOnSdkTimedout={true} attributes={attributes}>
        {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => {
          if (!isReady) return <Loader />;

          return children;
        }}
      </SplitFactoryProvider>
    );
  },
);
