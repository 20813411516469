import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: 16,
  },
  iconItem: {
    display: 'flex',
    alignSelf: 'center',
  },
  fileIconWrapper: {
    display: 'flex',
    marginRight: 16,
  },
  fileIcon: {
    width: 30,
    height: 35,
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '140%',
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '140%',
    color: colors.grey140,
  },
  removeIconButton: {
    padding: 6,
    margin: -6,
  },
}));
